import * as React from "react";

import "./service-card.scss";

interface IProps {
    title: string;
    subheading: string;
    id: string;
    imgs: string[];
}

const ServiceCard = (props: IProps): JSX.Element => {
    const { title, subheading, id, imgs } = props;
    return (
        <div className="service-item">
            <a className="service-link" data-bs-toggle="modal" href={`#${id}`}>
                <div className="service-hover">
                    <div className="service-hover-content">
                        <i className="fas fa-plus fa-3x"></i>
                    </div>
                </div>
                <img src={imgs[0]} alt={title} className="img-fluid" />
            </a>
            <div className="service-caption">
                <div className="service-caption-heading">{title}</div>
                <div className="service-caption-subheading text-muted">{subheading}</div>
            </div>
        </div>
    );
};

export default ServiceCard;