import * as React from "react";

import "./navbar.scss";

const Navbar = (): JSX.Element => {
    
    return (
        <nav className="navbar sticky-top navbar-expand-lg bg-w">
            <div className="container-fluid">
                <a className="navbar-brand ms-3" href="/">
                    <img className="navbar-image" src="./assets/img/navbar-logo.svg" alt="full-logo"/>
                </a>
                <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="/#home">HOME</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#services">LEISTUNGEN</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#about">ÜBER UNS</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#media">MEDIEN</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contact" id="contact">KONTAKT</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;