import * as React from "react";

import "./footer.scss";

const Footer = (): JSX.Element => {
  const date = new Date();

  return (
    <div className="footer d-flex flex-column">
      <div className="container d-flex flex-column align-items-center align-items-lg-start">
        <img
          src="./assets/img/footer-logo.svg"
          alt="footer-logo"
          className="footer-logo"
        />
        <div className="sections d-flex row justify-content-center align-items-start">
          <div className="section col col-l-4 d-flex flex-column align-items-center align-items-lg-start my-3 my-lg-0">
            <h1 className="section-title">DYNAMIC-AUTOMOTIVE GMBH</h1>
            <p className="section-text">
              Gutenbergstraße 16
              <br />
              71672 Marbach am Neckar
              <br />
              <br />
              <strong>
                +49 (0) 7144 8582690
                <br />
                info@dynamic-automotive.de
              </strong>
            </p>
          </div>
          <div className="section col col-l-4 d-flex flex-column align-items-center align-items-lg-start my-3 my-lg-0">
            <h1 className="section-title">ÖFFNUNGSZEITEN</h1>
            <p className="section-text">
              <strong>Montag bis Donnerstag</strong>
              <br />
              10-16 Uhr
              <br />
              <strong>Freitag</strong>
              <br />
              10-14 Uhr
              <br />
              <strong>Samstag und Sonntag</strong>
              <br />
              Geschlossen
            </p>
          </div>
          <div className="section col col-l-4 d-flex flex-column align-items-center align-items-lg-start my-3 my-lg-0">
            <h1 className="section-title">INFORMATION</h1>
            <a href="/imprint" className="section-link">
              Impressum
            </a>
            <a href="/privacy" className="section-link">
              Datenschutz
            </a>
            {/*
                            Deaktiviert bis shop oder cookies vorhanden
                            <a href="/#" className="section-link">AGB</a>
                            
                        */}
          </div>
        </div>
        <div className="socials">
          <a
            className="social"
            href="https://de-de.facebook.com/daPerformance/"
          >
            <img
              src="./assets/img/socials/f_logo_RGB-White_100.png"
              alt="facebook-logo"
              className="social-logo"
            />
          </a>
          <a
            className="social"
            href="https://www.instagram.com/dynamic_automotive/"
          >
            <img
              src="./assets/img/socials/Instagram_Glyph_White.svg"
              alt="instagram-logo"
              className="social-logo"
            />
          </a>
          <a className="social" href="https://wa.me/4971448582690">
            <img
              src="./assets/img/socials/wa-logo-white-100.png"
              alt="whatsapp-logo"
              className="social-logo"
            />
          </a>
        </div>
      </div>
      <div className="copyright d-flex flex-column flex-md-row justify-content-center">
        <span>&copy; copyright {date.getFullYear()}</span>
        <span className="mx-2 d-none d-md-inline">|</span>
        <span>dynamic-automotive GmbH</span>
        <span className="mx-2 d-none d-md-inline">|</span>
        <span>All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Footer;
