import * as React from "react";

import "./partners.scss";

const partners = [
    {
        id: 0,
        name: "SLH Sicherheitstechnik",
        imageUrl: "/assets/img/partners/slh-sicherheit.png",
    },
    {
        id: 1,
        name: "TÜV SÜD",
        imageUrl: "https://www.tuvsud.com/images/logo.svg",
    },
    {
        id: 2,
        name: "HG Motorsport",
        imageUrl: "/assets/img/partners/HG_LOGO.png",
    },
    {
        id: 3,
        name: "KW Suspensions",
        imageUrl: "https://www.dtsshop.de/en/media/revslider/kw-logo-normal.png",
    },
    {
        id: 4,
        name: "ST Suspensions",
        imageUrl: "/assets/img/partners/st-suspensions-logo.svg",
    },
    {
        id: 5,
        name: "Fischer Stahlflex",
        imageUrl: "/assets/img/partners/FISCHER-STAHLFLEX-LOGO.svg",
    },
    {
        id: 6,
        name: "Milltek Sport",
        imageUrl: "/assets/img/partners/milltek-logo.png",
    },
    {
        id: 7,
        name: "HJS Motorsport",
        imageUrl: "https://hjs-motorsport.de/assets/img/Etc/HJS_Motorsport_Tuning_Logo_2020.png",
    },
    {
        id: 8,
        name: "Wavetrac",
        imageUrl: "/assets/img/partners/wavetrac_d3_v10.png",
    },
];

const Partners = (): JSX.Element => {

    const partnerList = partners.map( partner => (
        <div className="col-12 col-lg-4 mt-4" key={partner.id}>
            <div className="image-wrapper d-flex align-items-center justify-content-center">
                <img 
                    src={partner.imageUrl} 
                    alt={partner.name} 
                />
            </div>
            <h4 className="partner-title">{partner.name}</h4>
        </div>
    ));

    return (
        <section id="partners" className="partners d-flex flex-column align-items-center">
            <h3 className="section-sub-header">Handverlesen von Dynamic Automotive</h3>
            <h1 className="section-header">Unsere Partner</h1>
            <div className="style-line-red" />
            <div className="container">
                <div className="row">
                    {partnerList}
                </div>
            </div>
        </section>
    )
};

export default Partners;