export const services = [
    {
        title: "Leistungssteigerung",
        subheading: "maximale Performance - optimale Leistung",
        id: "leistung",
        imgs: [
            "assets/img/photos/Dynamic-Automotive-46.jpg",
        ],
        desc: (
            <>
                <h3>Wir bringen Power für dein Auto!</h3>
                <p>Wir nutzen alle Möglichkeiten, die Leistung deines Autos zu steigern.</p>
                <h4>Chiptuning – wir optimieren das Steuergerät</h4>
                <p>Beim Chiptuning modifizieren wir die elektronische Motorsteuerung, Electronic Control Unit, entweder durch Einsatz eines modifizierten Steuergeräts oder indem wir die originale Software überschreiben. Was das bringt? Dadurch optimieren wir die Einspritzmenge, den Zündzeitpunkt und andere wichtige Parameter – mit dem Ziel, die Leistung maximal zu steigern.</p>
                <h4>Mechanische Leistungssteigerung – wir verbessern den Motor</h4>
                <p>Indem wir Teile am Motor verändern oder hinzufügen, verbessern wir die Leistung des Motors. Dafür bauen wir spezielle Teile ein:</p>
                <ul>
                    <li>größerer Ladeluftkühler</li>
                    <li>Sport-Abgasanlage</li>
                    <li>Sport-Katalysator</li>
                    <li>größerer Turbolader</li>
                </ul>
                <p>Du wirst begeistert sein, was dein Auto jetzt kann!</p>
                <a href="/contact" className="btn btn-primary">Kontakt aufnehmen</a>
            </>
        )
    },
    {
        title: "Leistungsprüfstand",
        subheading: "der MAHA LPS 3000 – perfekt für alle Antriebe",
        id: "pruefstand",
        imgs: [
            "assets/img/photos/Dynamic-Automotive-05.jpg",
        ],
        desc: (
            <>
                <h3>Diagnose auf höchstem Niveau</h3>
                <p>Erst wenn wir exakt wissen, was dein Auto momentan leistet, können wir dir genau sagen, was alles möglich ist.</p>
                <h4>So wird Tuning präzise nachvollziehbar!</h4>
                <p>Der MAHA LPS 3000 ist der Inbegriff eines hochpräzisen Leistungsprüfstands – und unerlässlich, wenn du das Maximum aus deinem Auto herausholen willst. Denn damit können wir die Leistung deines Fahrzeugs genau analysieren, um herauszufinden, wie hoch das Verbesserungs-Potenzial ist. </p>
                <p>Die Vorteile des MAHA LPS 3000</p>
                <ul>
                    <li>Hohe <strong>Einsatzflexibilität</strong>: unterstützt alle gängigen Fahrzeug-Betriebsarten für umfassende Tests, ob für konstante oder dynamische Messungen</li>
                    <li>Enorme <strong>Genauigkeit</strong>: ermittelt die Leistung hochpräzise durch die Messung parasitärer Verluste</li>
                    <li>Vielseitige <strong>Testmöglichkeiten</strong>: bietet Tachometerprüfung, Wegstreckenmessung, Beschleunigungsmessung und außerdem Last- und Fahrsimulationen</li>
                    <li>Detaillierte <strong>Auswertungen</strong>: Software ermöglicht kontinuierliche grafische Aufzeichnung wichtiger Parameter und schafft so Vergleichbarkeit von Testzyklen und verbessert die Feinabstimmung</li>
                </ul>
                <p>Der MAHA LPS 3000 wird wegen seiner Vielseitigkeit von vielen Automobilherstellern, im Motorsport und in der Forschung verwendet – genau das Richtige also für unsere Zwecke.</p>
                <h4>Das kann der MAHA LPS 3000</h4>
                <ul>
                    <li>Leistungsbereich bis zu 900 PS</li>
                    <li>Höchstgeschwindigkeit bis 300 km/h</li>
                    <li>Wirbelstrombremse</li>
                    <li>Umfangreiche Diagnose-Funktionen</li>
                    <li>Liefert objektive Daten und detaillierte Analysen</li>
                </ul>
                <p>Soll Ihr Fahrzeug auf den Prüfstand? Das ist bei uns ab <strong>120 €</strong> möglich.</p>
                <a href="/contact" className="btn btn-primary">Kontaktiere uns!</a>
            </>
        )
    },
    {
        title: "Fahrdynamik",
        subheading: "Wir sind KW Performance-Partner",
        id: "fahrdynamik",
        imgs: [
            "assets/img/photos/Dynamic-Automotive-18.jpg",
        ],
        desc: (
            <>
                <h3>Fahrdynamik – und dein Auto macht jetzt richtig Spaß</h3>
                <p>Die Fahrdynamik beschreibt das Verhalten deines Fahrzeugs, sobald verschiedene Kräfte auf es einwirken: in der Kurve, beim Beschleunigen oder Bremsen. Je besser die Fahrdynamik deines Autos, desto leichter zu kontrollieren und präziser zu fahren – und effizienter wird es auch.</p>
                <h4>Wir machen dein Auto großartig – durch optimierte Fahrdynamik!</h4>
                <p>Bevor wir loslegen, schauen wir uns dein Auto genau an: Wir vermessen die Achsen, wiegen die Radlast und arbeiten uns Stück für Stück zu einer optimierten Fahrdynamik vor.</p>
                <ol>
                    <li><strong>Reifen</strong> hochwertige Reifen bieten mehr Grip, sorgen für ein besseres Handling und verbessern das Fahrverhalten enorm, da sie der einzige Kontaktpunkt zwischen Fahrzeug und Fahrbahn sind</li>
                    <li><strong>Radaufhängung</strong> adaptive Radaufhängungen passen sich den Fahrbedingungen exakt an und verbessern Komfort und Handling</li>
                    <li><strong>Fahrwerk</strong> mit einem Sportfahrwerk mit optimierter Aufhängung, Bremsen und Lenkung spürst du direkt, wie dein Fahrzeug reagiert</li>
                    <li><strong>Aerodynamik</strong> ein Aerodynamik-Kit erhöht die Stabilität bei hohen Geschwindigkeiten und reduziert zugleich den Kraftstoffverbrauch</li>
                    <li><strong>Leistung</strong> mit einem Leistungskit erhöhen wir die Motorleistung, sorgen für eine bessere Beschleunigung und Höchstgeschwindigkeit</li>
                    <li><strong>Gewicht</strong> das Gewicht deines Fahrzeugs hat einen großen Einfluss auf die Fahrdynamik – wir wägen sorgfältig zwischen einer besseren Beschleunigung, agilerem Handling einerseits und Bremsverhalten und Kurvenstabilität andererseits ab</li>
                </ol>
                <p>Willst du mehr Spaß mit deinem Auto?</p>
                <a  href="/contact" className="btn btn-primary">Meld' dich bei uns!</a>
            </>
        )
    },
    {
        title: "Motorenrevision",
        subheading: "neues Leben für deinen Motor",
        id: "motorenbau",
        imgs: [
            "assets/img/photos/Dynamic-Automotive-52.jpg",
            "assets/img/photos/motor-rev-1.jpg",
            "assets/img/photos/motor-rev-2.jpg",
            "assets/img/photos/motor-rev-3.jpg",
        ],
        desc: (
            <>
                <h3>Hauptsache, dein Motor läuft wieder!</h3>
                <p>Es gibt viele Gründe, warum eine Motorenrevision nötig wird. Der eine Unfall, ein Motorschaden oder der vollkommen normale Verschleiß im Laufe der Zeit.</p>
                <h4>Dein Motor ist individuell – unser Vorgehen strategisch</h4>
                <p>Der Aufwand, den wir betreiben, um den Motor wieder instand zu setzen, hängt von dem Zustand des Motors ab. Bei einer einfachen Reparatur tauschen wir defekte Teile aus, bei einer Generalüberholung zerlegen wir den Motor komplett, säubern die Einzelteile und bauen ihn dann wieder auf.</p>
                <ol>
                    <li><strong>Diagnose</strong> wir ermitteln die Ursache des Problems mittels unserer umfangreichen Diagnosetools</li>
                    <li><strong>Demontage</strong> sobald wir wissen, wo das Problem liegt, demontieren wir den Motor, ein Prozess, der viel Erfahrung und Geschick erfordert</li>
                    <li><strong>Reparatur</strong> defekte oder abgenutzte Teile reparieren wir oder ersetzen sie bei Bedarf</li>
                    <li><strong>Remontage</strong> jetzt bauen wir den Motor wieder zusammen</li>
                    <li><strong>Prüfungen</strong> durch unsere Prüfmöglichkeiten stellen wir sicher, dass der Motor ordnungsgemäß funktioniert</li>
                </ol>
                <p>Die Kosten sind nur dann realistisch für uns abschätzbar, wenn wir den Motor demontiert haben. In jedem Fall erstellen wir dir ein individelles Angebot.</p>
                <p>Braucht dein Motor ein neues Leben?</p>
                <a href="/contact" className="btn btn-primary">Melde dich bei uns!</a>
            </>
        )
    },
    {
        title: "Werkstatt",
        subheading: "von Enthusiasten für Enthusiasten",
        id: "werkstatt",
        imgs: [
            "assets/img/photos/Dynamic-Automotive-51.jpg",
            "assets/img/photos/Dynamic-Automotive-22.jpg",
        ],
        desc: (
            <>
                <h3>Dein Auto hat einfach mehr verdient!</h3>
                <p>Als echte Autofans durch und durch nehmen wir Autos aller Marken und Hersteller an. Gleichzeitig haben wir uns in der Mini-Szene einen Namen gemacht, weil wir in der Lage sind, Minis in Bestform zu bringen – auch bei Autorennen. Außerdem sind wir KW Automotive Performance Partner Pro: aus Überzeugung!</p>
                <p>Wir bei dynamic automotive machen gute Autos großartig! Bei uns bekommst du:</p>
                <ul>
                    <li>Wartung und Reparatur</li>
                    <li>Motorinstandsetzung</li>
                    <li>Einbau von hochwertigen Tuningteilen</li>
                    <li>Präzise Fehleranalyse an Motor und Getriebe</li>
                </ul>
                <p>Wir wollen, dass du von unserer Arbeit begeistert bist. Deswegen sind wir bei all deinen Fragen und Anliegen gerne für dich da. Und um dein Fahrzeug kümmern wir uns mit größter Sorgfalt – schließlich sind wir mindestens genauso autobegeistert wie du!</p>
                <p>Hast du eine Frage?</p>
                <a href="/contact" className="btn btn-primary">Melde dich direkt bei uns!</a>
            </>
        )
    },
    {
        title: "Kleinserien-Entwicklung ",
        subheading: "wenn dein Auto etwas Besonderes braucht!",
        id: "entwicklung",
        imgs: [
            "assets/img/photos/Dynamic-Automotive-63.jpg",
            "assets/img/photos/cad-screenshot.jpg",
        ],
        desc: (
            <>
                <h3>Wir entwickeln Kleinserien für Tuningteile</h3>
                <p>Unser Team aus erfahrenen Technikern erfüllt gerne deine Wünsche. Wir setzen modernste Technologien und Verfahren ein, damit du höchste Qualität für dein Auto bekommst:</p>
                <ul>
                    <li>Konzeption und Entwicklung</li>
                    <li>Konstruktion und CAD-Simulation</li>
                    <li>Prototypenbau und -prüfung</li>
                    <li>Kleinserienfertigung</li>
                </ul>
                <p>Was wir schon alles entwickelt haben? Eine ganze Menge!</p>
                <ul>
                    <li>Schaltwegverkürzungen</li>
                    <li>Adapter für fahrzeugspezifische mechanische Ladedruckmessung</li>
                    <li>Fahrzeugtypische halterungen für Rundinstrumente</li>
                    <li>Ladeluftkühler</li>
                    <li>Sonderlösungen für Abgasanlagen</li>
                    <li>Ansaug- und Druckschläuche aus Silikon</li>
                </ul>
                <p>Hast du eine Idee?</p>
                <a href="/contact" className="btn btn-primary">Melde dich einfach!</a>
            </>
        )
    },
];