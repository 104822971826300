import * as React from "react";

const Imprint = (): JSX.Element => {

    return (
        <div className="imprint py-4">
            <div className="container">
            <h1 className="my-4">Impressum</h1>
            <h2 className="mb-2">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Dynamic-Automotive GmbH<br />
            Gutenbergstra&szlig;e 16<br />
            71672 Marbach am Neckar</p>
            <p>Handelsregister: HRB 791496<br />
            Registergericht: Amtsgericht Stuttgart</p>
            <p><strong>Vertreten durch:</strong><br />
            Geschäftsführer Ole- Johannes Sokoll</p>
            <h2 className="mb-2">Kontakt</h2>
            <p>Telefon: 07144 8582690<br />
            Telefax: 07144 8582699<br />
            E-Mail: info@dynamic-automotive.de</p>
            <h2 className="mb-2">Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            DE364276334</p>
            <h2 className="mb-2">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.</p>
            </div>
        </div>
    )
};

export default Imprint;