import * as React from "react";

import "./hero.scss";

const Hero = (): JSX.Element => {
    return (
        <section className="hero d-flex justify-content-start align-items-start" id="home">
            <div className="hero-text-container">
                <p><strong>PERFORMANCE IS</strong><br/><span className="underline">NOTHING</span> WITHOUT CONTROL</p>
            </div>
             
        </section>
    );
};

export default Hero;