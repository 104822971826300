import * as React from "react";

import ServiceCard from "./services/service-card";

import { services } from "../../data/services";

import "./services.scss";

const Services = (): JSX.Element => {


    const serviceCards = services.map(service => (
        <div className="col-lg-4 col-sm-6 mb-4" key={service.id}>
            <ServiceCard 
                title={service.title}
                subheading={service.subheading}
                id={service.id}
                imgs={service.imgs}
            />
        </div>
    ));

    return (
        <>
            <section className="services" id="services">
                <div className="container text-center d-flex flex-column align-items-center">
                    <h3 className="section-sub-header">Voller Einsatz für Dein Projekt</h3>
                    <h1 className="section-header">Unsere Leistungen</h1>
                    <div className="style-line-red" />
                    <div className="row mt-5">
                        {serviceCards}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Services;

