import * as React from 'react';

import "./instagram-widget.scss";

const InstagramWidget = (): JSX.Element => {

    return (
        <section className="instagram-widget d-flex flex-column align-items-center" id="media">
            <h3 className="section-sub-header">UNSERE</h3>
            <h1 className="section-header">Medien</h1>
            <div className="style-line-red" />
            <div className="container mt-5">
                <iframe 
                    src="//lightwidget.com/widgets/f6f12468d8cd53b081bf214e666361d9.html"
                    className="lightwidget-widget"
                    title="instagram-widget"
                    scrolling='no'
                />
            </div>
        </section>
    );
}

export default InstagramWidget;



