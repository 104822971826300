import * as React from "react";

import "./landing.scss"
import Services from "../components/landing/services";
import Hero from "../components/landing/hero";
import About from "../components/landing/about";
import Partners from "../components/landing/partners";
import InstagramWidget from "../components/landing/instagram-widget";

const Landing = (): JSX.Element => {

    return (
        <div className="landing" data-bs-spy="scroll" data-bs-target="navbarSupportedContent" data-bs-root-margin="0px 0px -35%">
            <Hero />
            <Services />
            <div className="transition">
                <img src="./assets/img/transition.svg" alt="transition" />
            </div>
            <About />
            <Partners />
            <InstagramWidget />
        </div>
    )
};

export default Landing;