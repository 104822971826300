import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';

import "./contact.scss";

const FILE_LIMIT = 5;
const SIZE_LIMIT_MB = 2;

interface Base64File {
    fileName: string,
    base64Image: string,
}

interface EmailRequest {
    name: string,
    email: string,
    message: string, 
    "g-recaptcha-response": string,
    images?: [ Base64File? ],
}

const Contact = (): JSX.Element => {
    const location = useLocation();

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    
    const [name, setName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [message, setMessage] = React.useState<string>("");
    const [emailIsValid, setEmailIsValid] = React.useState<boolean>(true);
    const [sendingSuccessful, setSendingSuccessful] = React.useState<boolean | null>(null);
    const [infoBannerMessage, setInfoBannerMessage] = React.useState<string>("");
    const [selectedImages, setSelectedImages] = React.useState<FileList | null>(null);
    const [fileSizeLimitExceeded, setFileSizeLimitExceeded] = React.useState<boolean>(false);
    const [fileCountLimitExceeded, setFileCountLimitExceeded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (location.pathname === '/contact') {
            const element = document.getElementById('contact');
            if (element) {
                element.classList.add('active');
            }
        }
    }, [location.pathname]);

    const captchaRef = React.useRef<ReCAPTCHA>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const token = captchaRef.current?.getValue();
        captchaRef.current?.reset();

        let requestObject: EmailRequest = {
            name: '',
            email: '',
            message: '',
            'g-recaptcha-response': '',
        };

        // let formdata = new FormData();
        if (token) {
            requestObject.name = name;
            requestObject.email = email;
            requestObject.message = message;
            requestObject['g-recaptcha-response'] = token;
            
            if (selectedImages && !fileSizeLimitExceeded && !fileCountLimitExceeded ) {
                requestObject.images = [];
                for (let i = 0; i < selectedImages.length; i++) {
                    const image = selectedImages[i];
                    const base64File = await readFileAsBase64(image);
                    requestObject.images?.push(base64File);
                }
            }
        }

        const response = await fetch("https://api.dynamic-automotive.de/contact.php", {
            method: "POST",
            body: JSON.stringify(requestObject),
        });

        switch (response.status) {
            case 200:
                setSendingSuccessful(true);
                setName("");
                setMessage("");
                setEmail("");
                setEmailIsValid(true);
                setSelectedImages(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                break;
            case 400:
                setSendingSuccessful(false);
                const resText = await response.text();
                if (resText.includes("captcha")){
                    setInfoBannerMessage("Fehler bei Überprüfung des Captcha.");
                } else {
                    setInfoBannerMessage("Fehler beim Übertragen der Daten.");
                }
                break;
            case 500:
                setSendingSuccessful(false);
                setInfoBannerMessage("Interner Fehler.");
                break;
        }
    };

    const validateAndSetEmail = (emailInput: string) => {
        setEmail(emailInput);
        const emailValidCheck = emailInput
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        // using truthy or falsey values for RegExpMatchArray
        if (emailValidCheck) {
            setEmailIsValid(true);
        } else {
            setEmailIsValid(false);
        }
    };

    const checkDisabled = (): boolean => {
        return !(name !== "" && email !== "" && message !== "" && emailIsValid);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files) {
            setFileCountLimitExceeded(files.length > FILE_LIMIT);
            for (const file of files) {
                const isExceeded = file.size > SIZE_LIMIT_MB * 1024 * 1024;
                setFileSizeLimitExceeded(isExceeded);
                if (isExceeded) break;
            }
            setSelectedImages(files);
        }
    };

    const readFileAsBase64 = (file: File): Promise<Base64File> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
        
            reader.onload = () => {
                if (reader.result) {
                const base64Image = reader.result.toString().split(',')[1];
                const fileName = file.name;
                resolve({fileName, base64Image});
                } else {
                reject(new Error('Failed to read file as base64.'));
                }
            };
        
            reader.onerror = (error) => {
                reject(error);
            };
        
            reader.readAsDataURL(file);
        });
    };

    const getInvalidFeedback = () => {
        if (fileCountLimitExceeded) {
            return (<label htmlFor="floatingfilepicker" className="invalid-feedback">Bitte maximal {FILE_LIMIT} Dateien auswählen.</label>);
        }
        if (fileSizeLimitExceeded) {
            return (<label htmlFor="floatingfilepicker" className="invalid-feedback">Eine ausgewählte Datei hat mehr als {SIZE_LIMIT_MB} MB.</label>);
        }
        return "";
    };

    return (
        <div className="contact">
            <div className="container">
                {
                    sendingSuccessful && 
                    (
                        <div 
                            className={`alert mb-4 ${sendingSuccessful ? "alert-success" : "alert-danger"}`} 
                            role="alert">
                                { 
                                    sendingSuccessful ? "Ihre Nachricht wurte erfolgreich gesendet. Sie erhalten in Kürze eine Bestätigung per Mail."
                                        : `Es ist ein Fehler aufgetreten: ${infoBannerMessage}`
                                }
                        </div>
                    ) 
                }
                <div className="row">
                    <div className="col-lg-7 d-flex flex-column justify-content-center">
                        <img className="mb-4" src="/assets/img/photos/Dynamic-Automotive-36.jpg" alt="contact-img" />
                    </div>
                    <div className="col-lg-5">
                        <h1 className="mb-4">Kontakt</h1>
                        <p className="mb-4">Du hast Fragen oder benötigst weitere Informationen? Kontaktiere uns gerne! Unser Team steht dir zur Verfügung und freut sich darauf, dir behilflich zu sein. Wir sind per Telefon, E-Mail oder persönlich in unserer Werkstatt erreichbar. Lass uns gemeinsam die besten Lösungen für dein Anliegen finden!</p>
                        <form onSubmit={handleSubmit} className="form-floating">
                            <div className="form-floating my-3">
                                <input type="text" value={name} id="floatingtext" className="form-control" placeholder="" onChange={e => setName(e.target.value)}/>
                                <label htmlFor="floatingtext">Vor- und Nachname</label>
                            </div>
                            
                            <div className="form-floating mb-3">
                                <input type="email" value={email} id="floatingmail" className={`form-control${emailIsValid ? "" : " is-invalid"}`} placeholder="" onChange={e => validateAndSetEmail(e.target.value)}/>
                                <label htmlFor="floatingmail">{`${emailIsValid ? "Email-Adresse" : "Ungültige Email Addresse"}`}</label>
                            </div>

                            <div className="form-floating mb-3">
                                <textarea name="message" value={message} id="floatingtextarea" className="form-control free-text" placeholder="" onChange={e => setMessage(e.target.value)}/>
                                <label htmlFor="floatingtextarea">Ihre Nachricht an uns</label>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="floatingfilepicker" className="mb-3">Bilder (z.B. Fahrzeugschein, max. {FILE_LIMIT} Bilder und max {SIZE_LIMIT_MB} MB pro Bild)</label>
                                <input type="file" name="file" ref={fileInputRef} id="floatingfilepicker" className={`form-control${fileCountLimitExceeded || fileSizeLimitExceeded ? " is-invalid" : ""}`} placeholder="" accept="image/*" multiple onChange={handleImageChange} />
                                { getInvalidFeedback() }
                            </div>
                            
                            <ReCAPTCHA 
                                sitekey="6Lca8qsiAAAAAPIZMyfEqs-x3nn_N9rOl40muu7-"
                                ref={captchaRef}
                                className="captcha mt-3"
                            />
                            <button type="submit" disabled={checkDisabled()} className="btn btn-lg btn-outline-primary mt-3 mb-4">Senden</button>
                        </form>
                    </div>
                </div>
            </div>  
        </div>
    );
};

export default Contact;