export const language = {
    default: "de",
    translations: {
        de: {
            consentModal: {
                title: "Cookies!",
                description: "Wir verwenden Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website haben. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. Weitere Informationen finden Sie in unserer Datenschutzrichtlinie.",
                acceptAllBtn: "Alle akzeptieren",
                acceptNecessaryBtn: "Alle ablehnen",
                showPreferencesBtn: "Einstellungen verwalten",
                footer: "<a href=\"/privacy\">Datenschutz</a>"
            },
            preferencesModal: {
                title: "Präferenzen für die Zustimmung",
                acceptAllBtn: "Alle akzeptieren",
                acceptNecessaryBtn: "Alle ablehnen",
                savePreferencesBtn: "Einstellungen speichern",
                closeIconLabel: "Modal schließen",
                serviceCounterLabel: "Dienstleistungen",
                sections: [
                    {
                        title: "Verwendung von Cookies",
                        description: "Wir verwenden Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website haben. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. Weitere Informationen finden Sie in unserer Datenschutzrichtlinie."
                    },
                    {
                        title: "Streng Notwendige Cookies <span class=\"pm__badge\">Immer Aktiviert</span>",
                        description: "Diese Website verwendet Cookies, einschließlich Google reCaptcha und LightWidget, um die Sicherheit zu gewährleisten und die Integration von Social-Media-Inhalten zu ermöglichen. Durch die Nutzung der Website stimmen Sie der Verwendung dieser Cookies zu. Weitere Informationen finden Sie in unserer Datenschutzrichtlinie.",
                        linkedCategory: "necessary"
                    },
                    /*
                    {
                        title: "Funktionalitäts Cookies",
                        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        linkedCategory: "functionality"
                    },
                    {
                        title: "Analytische Cookies",
                        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        linkedCategory: "analytics"
                    },
                    */
                    {
                        title: "Weitere Informationen",
                        description: "Für weitere Informationen und fragen: <a class=\"cc__link\" href=\"/contact\">Kontaktieren Sie uns.</a>."
                    }
                ]
            }
        }
    }
};