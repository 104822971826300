import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as CookieConsent from "vanilla-cookieconsent";

import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Contact from "./pages/contact";
import Landing from "./pages/landing";
import Imprint from "./pages/imprint";
import ServiceModal from "./components/landing/services/service-modal";
import { services } from "./data/services";
import { language } from "./data/cookie-lang";
import Privacy from "./pages/privacy";

const Main = (): JSX.Element => {

  React.useEffect(() => {
    CookieConsent.run({
      language,
      guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
      },
      categories: {
          necessary: {
              readOnly: true,
              enabled: true,
          },
          // functionality: {},
          // analytics: {}
      },
    });
  }, []);

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: (<Landing/>),
      },
      {
        path: "/contact",
        element: (<Contact/>),
      },
      {
        path: "/imprint",
        element: (<Imprint/>),
      },
      {
        path: "/privacy",
        element: (<Privacy/>),
      }
    ]
  );

  const serviceModals = services.map(service => (
    <ServiceModal 
        title={service.title}
        subheading={service.subheading}
        id={service.id}
        imgs={service.imgs}
        desc={service.desc}
        key={service.id}
    />    
  ));

  return (
    <>
      <Navbar/>
      <RouterProvider router={router} />
      <Footer />
      {serviceModals}
    </>
  )
};

export default Main;