import * as React from "react";

import "./about.scss";

const About = (): JSX.Element => {
    return (
        <section className="about" id="about">
            <div className="container d-flex flex-column align-items-center">
                <h3 className="section-sub-header">Präzision trifft Leidenschaft</h3>
                <h1 className="section-header">Über Uns</h1>
                <div className="style-line-red" />
                <h3>Wir sind die dynamic AUTOMOTIVE-Enthusiasten!</h3>
                <p>
                Seit über 15 Jahren bieten wir schon Leistungssteigerung für Autos aller Marken. Besonders ans Herz gewachsen sind uns in der Zeit MINI, BMW und die anderen Marken des VAG-Konzerns. Wir bieten dir und deinem Auto alles aus einer Hand. Nach so vielen Jahren sind wir immer noch begeistert von unserer Arbeit – hier verwirklichen wir Autoträume. Täglich! Brauchst du eine geänderte Abgasanlage? Willst du eine perfekt auf deine Bedürfnisse abgestimmte Leistungssteigerung oder willst du die Fahrdynamik deines Autos verbessern? Kein Problem – wir sind da!
                </p>
                <h4><strong>Unsere Leistungen im Überblick:</strong></h4>
                <ul>
                    <li>Leistungssteigerung, speziell für dich abgestimmt auf unserem MAHA LPS 3000 Prüfstand</li>
                    <li>KW-Performance Partner Pro für ein perfekt abgestimmtes Fahrwerk</li>
                    <li>Fachwerkstatt für MINI und BMW</li>
                    <li>Entwicklung und Verkauf von speziellen Tuningteilen in Kleinserie</li>
                    <li>Eigene Schweißerei</li>
                    <li>Online Shop (coming soon)</li>
                </ul>
            </div>
        </section>
    );
};

export default About;