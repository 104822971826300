import * as React from "react";

import "./service-modal.scss";

interface IProps {
    title: string;
    subheading: string;
    id: string;
    imgs: string[];
    desc: JSX.Element;
}

const ServiceModal = (props: IProps): JSX.Element => {
    const { title, subheading, id, imgs, desc } = props;

    const carouselImages = imgs.map((img, i) => (
        <div className={`carousel-item${ i === 0 ? " active" : ""}`}>
            <img src={img} className="d-block w-100" alt={img} />
        </div>
    ));

    const carouselIndicators = imgs.map((_, i) => (
        <button type="button" data-bs-target={`#carousel-${id}`} data-bs-slide-to={i} className={i === 0 ? "active" : ""} aria-current={i === 0 ? "true" : "false"} aria-label={`Slide ${i + 1}`}></button>
    ));

    const getCarouselControls = () => {
        if (imgs.length > 1) {
            return (
                <>
                    <div className="carousel-indicators">
                        {carouselIndicators}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${id}`} data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${id}`} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </>
            );
        }
    };

    return (
        <div className="service-modal modal fade" id={id} tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-bs-dismiss="modal">
                        <img src="assets/img/close-icon.svg" alt="Close modal" />
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                    <h2 className="text-uppercase">{title}</h2>
                                    <p className="item-intro text-muted">{subheading}</p>
                                    <div id={`carousel-${id}`} className="carousel slide service-carousel" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            {carouselImages}
                                        </div>
                                        {getCarouselControls()}
                                    </div>
                                    <div className="service-modal-body">
                                        {desc}
                                    </div>
                                    <button className="btn btn-outline-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i className="fas fa-xmark me-1"></i>
                                        Schließen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceModal;