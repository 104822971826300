import * as React from 'react';
import 'bootstrap';

import ReactDOM from 'react-dom/client';
import Main from './main';
import reportWebVitals from './reportWebVitals';

import "vanilla-cookieconsent/dist/cookieconsent.css";

import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Main />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
